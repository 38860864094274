.container {
  width: calc(360px * 0.9);
  min-width: calc(360px * 0.9);
  max-height: 200px;
  z-index: 10;
  background-color: #ffffff;
  overflow: hidden !important;
}

.items {
  text-align: left;
  width: 90%;
  height: 100%;
  max-height: inherit;
  border: 1px solid black;
  overflow-y: scroll;
  position: relative;
}

.items > div:first-of-type {
  position: sticky;
  top: 0;
}

.items:hover {
  cursor: pointer;
}

.phoneCode {
  padding: 1rem 0 1rem 1rem;
  background-color: white;
  border: 1px solid black;
  border-right: none;
  outline: none;
}

.phoneCode:hover {
  cursor: pointer;
}

.phoneCodeSearch {
  width: 100%;
  outline: none;
  border: 1px solid black;
  padding: 0.5rem;
}
