.messages-action-dropdown {
	width: 130px;
}

.messages-action-dropdown button {
	border-radius: 8px;
	padding: 3px 8px;
}

.messages-action-dropdown-container {
	position: absolute;
	top: 110%;
	left: -25%;
	z-index: 1;
	background: #ccc;
	width: 100%;
	text-align: center;
	padding: 5px 0;
}

.messages-action-dropdown-menu {
	background-color: #ccc;
}

.messages-action-dropdown-container p,
.messages-action-dropdown-item {
	margin-bottom: 0;
	padding: 5px 10px;
	text-align: center;
	/* color: #fff; */
	cursor: pointer;
	font-weight: 600;
	color: inherit;
}

.messages-action-dropdown-container p:hover,
.messages-action-dropdown-item:hover {
	background-color: #fff;
	color: inherit;
}

/* .channelUsersLabel {
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.channelUsersLabel input {
  position: absolute;
  top: -9999px;
} */

.full-height {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.fixed-bottom-container {
	position: fixed;
	bottom: 0;
	max-width: 100%; /* Ensure it takes the full width of its parent */
	background: white; /* Optional: Set background color to match the layout */
	z-index: 1000; /* Optional: Ensure it's on top of other elements */
	padding: 10px; /* Optional: Add padding if needed */
}

.gap-3 {
	gap: 10px;
}

.full-width-button {
	width: 100%;
}
.moderation-button {
	background-color: #1505cc;
	color: white;
}

.userPost-userActions-container {
	margin-top: auto; /* Pushes the element to the bottom */
	width: 100%;
}

.userPost-userActions {
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* font-size: 12px; */
	gap: 10px;
}

.userPost-moderation-indicator {
	margin-left: 0;
	width: max-content;
}

.userPost-moderation-indicator span {
	margin-left: 5px;
	display: inline-block;

	color: orange;
	font-size: 7px;
	height: max-content;
	border-radius: 50%;
}
