@import url("./_user-posts.css");

@font-face {
	font-family: "Scriber Bold";
	src: url("../assets/fonts/ScriberBold.ttf") format("truetype");
}

@font-face {
	font-family: "Barlow";
	src: url("../assets/fonts/Barlow-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "RobotoMono";
  src: url("../assets/fonts/RobotoMono-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "BarlowBold";
  src:url("../assets/fonts/Barlow-Bold.ttf");
}

@font-face{
  font-family: "Cormorant";
  src:url("../assets/fonts/Cormorant-Regular.ttf");
}

@font-face{
  font-family: "CormorantBold";
  src:url("../assets/fonts/Cormorant-Bold.ttf");
}


body {
	background-color: rgb(207, 207, 229);
}

.scriber-bold {
	font-family: "Scriber Bold";
}

.barlow {
	font-family: "Barlow";
}

.yellow-arrow {
	width: 20px;
}

.blue-text {
	color: #1505cc;
}
.blue-border {
	border: 3px solid blue;
	border-radius: 0px !important;
}
.blue-btn-outline {
	border-color: blue;
	color: blue;
}
.blue-btn-outline:hover,
.blue-btn-outline:focus,
.blue-btn-outline:active {
	background-color: blue;
	color: white;
}
.blue-banner {
	background-color: #1505cc;
	border-radius: 0px;
	border: none;
	color: white;
	font-size: 1.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
}
.blue-banner:hover,
.blue-banner:focus,
.blue-banner:active {
	background-color: rgb(3, 3, 174);
	color: white;
}

.btn-secondary {
	background-color: rgb(89, 89, 89);
}

.btn-danger {
	background-color: rgb(255, 0, 0);
	color: white;
}

.red-btn-outline {
	border-color: red;
	color: red;
}

.red-btn-outline:hover,
.red-btn-outline:focus,
.red-btn-outline:active {
	background-color: red;
	color: white;
}

.btn-outline-danger:disabled {
	border-color: #c4183c;
}

.channel-buttons {
	font-size: 1rem;
	padding-left: 0.3rem;
	padding-right: 0.5rem;
}
.channel-buttons.active {
	background-color: #0b0061 !important;
	border-color: #0b0061 !important;
	color: #fff606 !important;
}

.navbar {
	height: auto !important;
}

.main-navbar {
	z-index: 1080;
}

.main-sidebar {
	z-index: 1070;
	overflow: auto;
	padding-bottom: 15px;
}

.publish_modal {
  position: fixed;
  width: 100vw;
  min-width: 320px;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.publish_container_box {
  width: 50%;
  margin: auto;
  background-color: #ffffff;
  border: 1px solid blue;
}

.publish_backdrop {
  display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;
}

.flex {
	display: flex;
	gap: 8px;
	justify-content: center;
}
