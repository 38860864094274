/* Set default font-family */
.app .ql-editor {
  font-family: "Barlow";
}

/* Set dropdown font-families */

.ql-picker.ql-font .ql-picker-label[data-value="Barlow"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Barlow"]::before
{
  font-family: "Barlow", cursive;
  content: "Barlow";
}
.ql-picker.ql-font .ql-picker-label[data-value="Cormorant"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Cormorant"]::before
{
  font-family: "Cormorant", cursive;
  content: "Cormorant";
}
.ql-picker.ql-font .ql-picker-label[data-value="RobotoMono"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="RobotoMono"]::before
{
  font-family: "RobotoMono", cursive;
  content: "RobotoMono";
}
.ql-picker.ql-font .ql-picker-label[data-value="BarlowBold"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="BarlowBold"]::before
{
  font-family: "BarlowBold", cursive;
  content: "BarlowBold";
}
.ql-picker.ql-font .ql-picker-label[data-value="CormorantBold"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="CormorantBold"]::before
{
  font-family: "CormorantBold", cursive;
  content: "CormorantBold";
}
/* Set content font-families */
.ql-font-Cormorant {
  font-family: "Cormorant-Regular";
}
.ql-font-RobotoMono {
  font-family: "RobotoMono";
}
.ql-font-BarlowBold {
  font-family: "BarlowBold";
}
.ql-font-CormorantBold {
  font-family: "CormorantBold";
}

.add-details {
  min-height: 150px;
}

.ql-container .ql-editor {
  min-height: 100px;
}
/* We do not set Courier since it is the default font */